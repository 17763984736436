import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { tagular } from "@cohesion/tagular";
import { SailyLogo } from "@pageComponents/best-in-travel-2025/saily/sailyLogo";
import { ImgixNextImage } from "@components/imgixNextImage";

export function SailyAd({ url }) {
  const hasRendered = useRef(false);
  useEffect(() => {
    if (!hasRendered.current) {
      tagular("product_view", {
        product: {
          location: `ARTICLES DRIVER_SAILY`,
          text: `SAILY_ARTICLE`,
          position: 0,
          brand: "SAILY",
        },
      });
    }
    hasRendered.current = true;
  }, []);

  return (
    <div className="border-[#e2edff] border bg-[#F1F6FE] rounded-md p-4">
      <header className="flex flex-col md:flex-row">
        <ImgixNextImage
          src="https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/saily-article.jpeg?w=242&h=215&fit=crop&auto=format&q=75"
          alt="alt text"
          fill
          width={242}
          height={215}
          quality={85}
          imgixParams={{ w: 242, h: 215, dpr: 2 }}
          className="w-full md:max-w-[242px] aspect-[311/215] md:aspect-[242/215] object-cover rounded-md overflow-hidden"
        />
        <div className="flex-col mt-4 md:mt-0 md:ml-6">
          <div className="flex items-center gap-1 text-xs leading-none">
            {/* TODO - Improve sharpeness of this logo */}
            <SailyLogo className="mb-4 w-[75px] h-[32px] md:w-[54px] md:h-[22px]" />
          </div>
          <p className="pb-3 text-2xl leading-none">
            The best way to stay connected.
          </p>

          <p className="pt-2 pb-6 md:pt-4 md:pb-8">
            Saily provides a hassle-free solution to travel data — choose your
            data plan with a Holiday deal for up to 5 GB of free mobile data and
            prepare for your trip. Go online as soon as you arrive at your
            destination.
          </p>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={url}
            className="block btn btn-primary w-content"
            target="_blank"
            rel="noopener nofollow"
            onClick={() => {
              tagular("click", {
                actionOutcome: "EXTERNALLINK",
                outboundUrl: url,
                webElement: {
                  location: "PLANNINGDRIVER",
                  elementType: "BUTTON",
                  position: "0",
                  text: "GET YOUR ESIM",
                  name: "ARTICLE",
                },
              });
            }}
          >
            Get your eSIM
          </a>
        </div>
      </header>
    </div>
  );
}

SailyAd.propTypes = {
  url: PropTypes.string.isRequired,
};
